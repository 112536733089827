import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {SelectStyles} from '../General/SelectStyles';
import PSAlert from "../General/PSAlert";

export default function TPMapTypeField(props) {
    const [mapTypes, setMapTypes] = useState([
        {value: 'google', label: 'Google'},
        {value: 'mapbox', label: 'MapBox'},
        {value: 'bing', label: 'Bing'}
    ]);
    const [alertOpen, setAlertOpen] = useState(false);
    const [acceptDisabled, setAcceptDisabled] = useState(false);
    const [nearmapPhotos, setNearmapPhotos] = useState(null);
    const [nearmapPhotoSelected, setNearmapPhotoSelected] = useState(null);
    const [bbox, setBBox] = useState(null);

    const [popupActivated, setPopupActivated] = useState(true);


    useEffect(() => {
        if (props.nearmapStatus === 'available' || props.nearmapStatus === 'used_by_default') {
            if (!mapTypes.find(type => type.value === 'nearmap')) {
                setMapTypes([
                    {value: 'nearmap', label: 'Nearmap HD'},
                    ...mapTypes,
                ]);
            }
        }
    }, [props.nearmapStatus]);


    useEffect(() => {
        if (props.value === 'nearmap') {
            fetchNearmapPhotos(window.appComponent.projectUID);
        }
    }, []);


    useEffect(() => {
        if (props.value === 'nearmap') {
            fetchNearmapPhotos(window.appComponent.projectUID);
        }
    }, [props.value]);


    useEffect(() => {
        if (props.nearmapData !== null) {
            console.log(props.nearmapData);

            const bboxNew = props.nearmapData.bbox.split(',');
            setBBox(bboxNew);

            let photos = [];
            for (const photo of props.nearmapData.photos) {
                photos.push({
                    value: photo.value,
                    label: photo.label,
                });
            }
            setNearmapPhotos(photos);

            let selectedPhoto = photos[0]
            if (props.nearmapData.nearmapPhoto) {
                selectedPhoto = photos.find(photo => photo.value === props.nearmapData.nearmapPhoto);
            }
            //if (props.value === 'nearmap') {
                nearmapPhotoChanged(selectedPhoto, bboxNew);
            //}

            props.onChange('nearmap');
        }
    }, [props.nearmapData]);


    const fetchNearmapPhotos = (uid) => {
        /*if (nearmapPhotos !== null) {
            return new Promise((resolve, reject) => {
                resolve();
            });
        }*/

        return new Promise((resolve, reject) => {
            setPopupActivated(false);

            window.appComponent.apiHelper.getNearmapPhotos(uid).then((result) => {
                //console.log('fetchNearmapPhotos');
                if (result.status) {
                    const bboxNew = result.data.bbox.split(',');
                    setBBox(bboxNew);

                    let photos = [];
                    for (const photo of result.data.photos) {
                        photos.push({
                            value: photo.value,
                            label: photo.label,
                        });
                    }
                    setNearmapPhotos(photos);

                    let selectedPhoto = photos[0]
                    if (result.data.nearmapPhoto) {
                        selectedPhoto = photos.find(photo => photo.value === result.data.nearmapPhoto);
                    }
                    //if (props.value === 'nearmap') {
                        nearmapPhotoChanged(selectedPhoto, bboxNew);
                    //}

                    props.onChange('nearmap');
                    resolve();
                } else {
                    setAlertOpen(false);
                    setAcceptDisabled(false);

                    if (props.route !== 'view') {
                        window.appComponent.notifier.warningCentered('Nearmap is not available', '');
                    }

                    typeChanged({value: 'google'});
                }
            });
        });
    }


    const typeChanged = (selectedOption) => {
        if (selectedOption.value !== props.value) {
            if (selectedOption.value === 'nearmap') {
                if (popupActivated) {
                    setAlertOpen(true);
                } else {
                    onAlertAccept();
                }
            } else {
                props.onChange(selectedOption.value);
            }
        }
    }


    const getSelectedType = () => {
        if (props.value) {
            return mapTypes.find(type => type.value === props.value);
        }

        return null;
    }


    const onAlertOpenChanged = (open) => {
        setAlertOpen(open);
    }


    const onAlertAccept = () => {
        setAcceptDisabled(true);
        fetchNearmapPhotos(window.appComponent.projectUID).then(() => {
            setAlertOpen(false);
            setAcceptDisabled(false);
            setPopupActivated(false);
        });
    }


    const nearmapPhotoChanged = (selectedOption, bboxNew) => {
        if (!selectedOption) {
            return;
        }
        if (selectedOption.value !== nearmapPhotoSelected) {
            setNearmapPhotoSelected(selectedOption.value);

            let bboxToSend = bbox;
            if (bboxNew && (typeof bboxNew === 'object') && (bboxNew.length === 4)) {
                bboxToSend = bboxNew;
            }

            props.onNearmapPhotoChanged(selectedOption.value, bboxToSend);
        }
    }


    const getNearmapPlaceholder = () => {
        const template = 'Imagery for {%DATE%}'; // Jun 17, 2023

        const selectedPhoto = nearmapPhotos.find(photo => photo.value === nearmapPhotoSelected);
        if (selectedPhoto) {
            return template.replace('{%DATE%}', selectedPhoto.label);
        }

        return "Select Nearmap Imagery";
    }


    return (
        <>
            <div className="form-group">
                <Select
                    name="map-type"
                    classNamePrefix="ps-"
                    value={getSelectedType()}
                    placeholder="Map Type"
                    options={mapTypes}
                    onChange={typeChanged}
                    isSearchable={false}
                    isMulti={false}
                    styles={SelectStyles}
                />
            </div>

            {nearmapPhotos && (props.value === 'nearmap') && (
            <div className={'nearmap-selector-container'}>
                <Select
                    name="nearmap-photo"
                    classNamePrefix="ps-"
                    value={nearmapPhotoSelected}
                    placeholder={getNearmapPlaceholder()}
                    options={nearmapPhotos}
                    onChange={nearmapPhotoChanged}
                    isSearchable={false}
                    isMulti={false}
                    styles={SelectStyles}
                />
            </div>
            )}

            <PSAlert
                open={alertOpen}
                title="Confirm Nearmap HD Selection"
                description="Please confirm if you wish to proceed with NearMap as your chosen map provider. You can easily switch to a different map provider anytime."
                cancelButtonLabel="Cancel"
                confirmButtonLabel="Confirm"
                confirmButtonVariant="blue"
                acceptDisabled={acceptDisabled}
                cancelDisabled={acceptDisabled}
                confirmLoading={acceptDisabled}
                btnFixWidth={83}
                openChanged={onAlertOpenChanged}
                onAccept={onAlertAccept}
            />
        </>
    );
}
