import Notifier from "../helpers/Notifier";
import * as L from "leaflet";
import ShadeMap from 'leaflet-shadow-simulator'
import "leaflet-bing-layer";
import "leaflet.gridlayer.googlemutant";
import NearmapsOverlay from "../SolarPanels/NearmapsOverlay";

class MapHelper {
    getGoogleDefaults = () => {
        return {
            defaultZoomLevel: 21,
            baseMinZoom: 21,
            minZoom: 21,
            baseMaxZoom: 22,
            maxZoom: 22,
            center: L.latLng(34.0694786894214, -118.5514571517706)
        };
    }


    getMapboxDefaults = () => {
        return {
            defaultZoomLevel: 22,
            baseMinZoom: 20,
            baseMaxZoom: 23,
            center: L.latLng(34.0694786894214, -118.5514571517706)
        };
    }


    constructor(map) {
        this.map = map;
        this.notifier = new Notifier();
    }


    setDefaultParameters(mapType, route) {
        let defaults = {};

        defaults.defaultZoomLevel = 21;
        defaults.baseMinZoom = 19.8;
        defaults.baseMaxZoom = 23.1;

        if (mapType === 'google') {
            defaults = this.getGoogleDefaults();
        }

        if (mapType === 'mapbox') {
            defaults = this.getMapboxDefaults();
        }

        defaults.center = L.latLng(34.0694786894214, -118.5514571517706);

        this.defaults = defaults;
        return defaults;
    }


    createMapboxTilesLayer() {
        return L.tileLayer('https://api.mapbox.com/styles/v1/pipesolar/clavj4onf008015o2xjsjfwke/tiles/256/{z}/{x}/{y}@2x?access_token=' + process.env.REACT_APP_MAPBOX_TOKEN + '&{randint}', {
            attribution: '',
            randint: Math.floor(Math.random() * 200000) + 1,
            tileSize: 512,
            detectRetina: true,
            zoomOffset: -1,
            minZoom: this.defaults.baseMinZoom,
            maxZoom: this.defaults.baseMaxZoom
        });
    }


    createBingTilesLayer() {
        let tilesBing = L.tileLayer.bing({
            bingMapsKey: process.env.REACT_APP_BING_TOKEN,
            imagerySet: 'AerialWithLabelsOnDemand',
            detectRetina: true,
            minZoom: this.defaults.baseMinZoom,
            maxZoom: this.defaults.baseMaxZoom
        });

        return tilesBing;
    }


    createGoogleTilesLayer() {
        return L.gridLayer.googleMutant({
            type: "hybrid", // valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
            minNativeZoom: this.defaults.baseMinZoom,
            minZoom: this.defaults.baseMinZoom,
            maxNativeZoom: 21,
            maxZoom: this.defaults.baseMaxZoom
        });
    }


    // https://ted-piotrowski.github.io/leaflet-shadow-simulator/examples/map.html
    createShadowLayer() {
        return new ShadeMap({
            //date: new Date(),
            date: new Date('2023-11-21 15:10:00'),
            color: '#01112f',
            opacity: 0.5,
            apiKey: process.env.REACT_APP_SHADOW_MAP_OVERLAY_KEY,
            terrainSource: {
                tileSize: 256,
                maxZoom: 15,
                getSourceUrl: ({x, y, z}) => {
                    return `https://s3.amazonaws.com/elevation-tiles-prod/terrarium/${z}/${x}/${y}.png`
                },
                getElevation: ({r, g, b, a}) => {
                    return (r * 256 + g + b / 256) - 32768
                }
            },
        });
    }



    createNearmapLayer(photo, bbox) {
        if (photo) {
            return new NearmapsOverlay().getOverlay(photo, bbox);
        } else {
            return null;
        }
    }
}

export default MapHelper;