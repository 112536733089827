import React, { Component } from 'react';
import * as L from 'leaflet';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import MapHelper from './../../helpers/MapHelper';
import NearmapsOverlay from "../../SolarPanels/NearmapsOverlay";



class MapContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            center: props.center
        };

        this.mapContainer = React.createRef();
        this.zoomControlRef = React.createRef();

        this.fitNearmapsIntoViewport = this.fitNearmapsIntoViewport.bind(this);
    }
    componentDidMount() {
        if (window.appComponent.state.gmAPILoaded) {
            this.initMap();
        } else {
            window.addEventListener('google-maps-loaded', () => this.initMap());
        }
    }


    initMap() {
        this.map = L.map(this.mapContainer.current, {
            attributionControl: false,
            fadeAnimation: true,
            zoomAnimation: true,
            boxZoom: false,
            doubleClickZoom: false,
            scrollWheelZoom: false,
            maxBoundsViscosity: 1.0,
            //touchZoom: false,
            zoomDelta: 0.3,
            zoomSnap: 0.3,
            zoomControl: false
        });

        if (window.appComponent.route !== 'view' && !this.zoomControlRef.current) {
            this.zoomControlRef.current = L.control.zoom({
                position: 'bottomright'
            });
            this.zoomControlRef.current.addTo(this.map);
        }

        this.helper = new MapHelper(this.map);

        this.defaults = this.helper.setDefaultParameters(this.props.mapType);
        this.createTileLayers();

        this.setDefaultView();

        if (window.appComponent.route === 'view') {
            this.map.dragging.disable();
            this.map.touchZoom.disable();
            this.map.doubleClickZoom.disable();
            this.map.scrollWheelZoom.disable();
            this.map.tap && this.map.tap.disable();
        }

        this.map.on('zoomend', (event) => {
            if (document.querySelector('#tmpzoom')) {
                document.querySelector('#tmpzoom').innerHTML = 'Zoom: ' + this.map.getZoom();
            }
        });
        if (document.querySelector('#tmpzoom')) {
            document.querySelector('#tmpzoom').innerHTML = 'Zoom: '+this.map.getZoom();
        }

        this.props.onMapInitialized(this.map);
    }


    centerChanged(e) {
        const mapCenter = this.map.getCenter();

        if (this.state.center.lat && this.state.center.lng && !mapCenter.equals(this.state.center) || !this.state.center.lat) {
            this.setState({
                center: mapCenter
            }, function() {
                this.props.onCenterChanged(mapCenter);
            });
        }
    }



    setDefaultView() {
        /*this.setState({
            center: this.defaults.center
        });*/
        let defaultCenter = this.defaults.center;
        if (this.props.center && this.props.center.lat && this.props.center.lng) {
            defaultCenter = this.props.center;
        }

        this.map.setView(defaultCenter, this.defaults.defaultZoomLevel);
    }



    createTileLayers() {
        this.tilesMapbox = this.helper.createMapboxTilesLayer();
        this.tilesBing = this.helper.createBingTilesLayer();
        this.tilesGoogleMaps = this.helper.createGoogleTilesLayer();
        this.overlayNearmap = this.helper.createNearmapLayer(this.props.nearmapPhoto, this.props.nearmapBBox);

        //this.shadowLayer = this.helper.createShadowLayer();
        this.shadowLayer = null;

        this.updateMapType();
    }



    updateMapType(mapType, nextNearmapPhoto, nextNearmapBBox) {
        if (this.map === undefined) {return;}

        if (mapType === undefined) {mapType = this.props.mapType;}

        if (mapType === 'nearmap' && !this.overlayNearmap) {
            if (nextNearmapPhoto && nextNearmapBBox) {
                this.overlayNearmap = this.helper.createNearmapLayer(nextNearmapPhoto, nextNearmapBBox);
            } else {
                this.overlayNearmap = this.helper.createNearmapLayer(this.props.nearmapPhoto, this.props.nearmapBBox);
            }
        }

        const layers = {
            bing: this.tilesBing,
            google: this.tilesGoogleMaps,
            mapbox: this.tilesMapbox,
            shadows: this.shadowLayer,
            nearmap: this.overlayNearmap
        };
        if (!layers.hasOwnProperty(mapType)) {mapType = 'google';}
        this.mapType = mapType;

        if (layers[mapType] && !this.map.hasLayer(layers[mapType])) {
            for (const layer of Object.values(layers)) {
                if (layer) {
                    this.map.removeLayer(layer);
                }
            }

            layers[mapType].addTo(this.map);
            if (mapType === 'google') {
                setTimeout(() => {
                    this.map.invalidateSize();
                }, 2000);
            }
        }
        if (mapType !== 'nearmap' && this.overlayNearmap) {
            this.overlayNearmap.remove();
        }

        this.defaults = this.helper.setDefaultParameters(mapType, this.props.route);
        if (mapType === 'google') {
            this.map.options.zoomDelta = 1;
            this.map.options.zoomSnap = 1;
        } else {
            this.map.options.zoomDelta = 0.3;
            this.map.options.zoomSnap = 0.3;
        }
        this.map.options.minZoom = this.defaults.baseMinZoom;
        this.map.options.maxZoom = this.defaults.maxZoom ? this.defaults.maxZoom : this.defaults.baseMaxZoom;

        if (mapType === 'nearmap' && this.overlayNearmap) {
            if (!this.map._loaded) {
                this.map.on('load', () => {
                    this.fitNearmapsIntoViewport();
                });
            } else {
                this.fitNearmapsIntoViewport();
            }
        } else {
            if (this.map.options.maxBounds) {
                this.map.setMaxBounds(null);
            }

            if (mapType === 'google' && this.map.getZoom() !== Math.round(this.map.getZoom())) {
                this.map.setZoom(Math.floor(this.map.getZoom()));
            }

            if (mapType === 'google') {
                if (!this.zoomControlRef.current) {
                    this.zoomControlRef.current = L.control.zoom({
                        position: 'bottomright'
                    });
                    this.zoomControlRef.current.addTo(this.map);
                }
            }
        }

        /*if (!this.map.hasLayer(this.shadowLayer) && this.map._loaded) {
            this.shadowLayer.addTo(this.map);
        }*/
    }


    fitNearmapsIntoViewport() {
        const viewportDistance = this.map.distance(this.map.getBounds().getSouthWest(), this.map.getBounds().getNorthEast());
        const overlayDistance = this.map.distance(this.overlayNearmap.getBounds().getSouthWest(), this.overlayNearmap.getBounds().getNorthEast());
        const ratio = viewportDistance / overlayDistance;
        let nextZoom = this.map.getScaleZoom(ratio, this.map.getZoom());
        nextZoom = (Math.ceil(nextZoom / this.map.options.zoomDelta) + 1) * this.map.options.zoomDelta;
        this.map.setView(this.overlayNearmap.getBounds().getCenter(), nextZoom, {animate: false});
        this.map.options.minZoom = nextZoom;
        this.map.setMaxBounds(this.overlayNearmap.getBounds());
    }


    updateNearmapPhoto(photo, bbox) {
        if (this.overlayNearmap) {
            new NearmapsOverlay().updateOverlay(this.overlayNearmap, photo, bbox);
        } else if (this.props.mapType === 'nearmap') {
            if (this.props.mapType === 'nearmap') {
                this.overlayNearmap = this.helper.createNearmapLayer(photo, bbox);

                this.overlayNearmap.addTo(this.map);
            }
        }
    }



    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.map === undefined) {return true;}

        //check if center changed
        const currentCenter = this.map.getCenter();
        let propsCentersEqual = true;
        if (
            !nextProps.center.lat || !nextProps.center.lng ||
            !this.props.center.lat || !this.props.center.lng ||
            nextProps.center.lat != this.props.center.lat ||
            nextProps.center.lng != this.props.center.lng
        ) {
            propsCentersEqual = false;
        }
        if (
            !propsCentersEqual && nextProps.hasOwnProperty('center') && nextProps.center.lat && nextProps.center.lng && !currentCenter.equals(nextProps.center) ||
            !propsCentersEqual && nextState.hasOwnProperty('center') && nextState.center.lat && nextState.center.lng && !currentCenter.equals(nextState.center)
        ) {
            this.map.setView(((nextProps.hasOwnProperty('center') && nextProps.center.lat && nextProps.center.lng) ? (nextProps.center) : (nextState.center)), this.defaults.defaultZoomLevel, {animate: false});
        }

        //check if map type changed
        if (
            nextProps.hasOwnProperty('mapType') && nextProps.mapType != this.mapType ||
            nextState.hasOwnProperty('mapType') && nextState.mapType != this.mapType
        ) {
            const newMapType = (nextProps.hasOwnProperty('mapType') ? nextProps.mapType : nextState.mapType);
            this.updateMapType(newMapType);
        }

        //check if nearmap photo changed
        if (
            nextProps.hasOwnProperty('nearmapPhoto') && nextProps.nearmapPhoto !== this.props.nearmapPhoto
        ) {
            this.updateMapType(nextProps.mapType, nextProps.nearmapPhoto, nextProps.nearmapBBox);
            this.updateNearmapPhoto(nextProps.nearmapPhoto, nextProps.nearmapBBox);
        }

        return false;
    }


    render() {
        return (
            <div id="mapContainer" ref={this.mapContainer}>

            </div>
        );
    }
}

export default MapContainer;
